import styled from "styled-components";
import { colors } from "../colors";

export const MainBody = styled.div`
  background-color: ${(props) =>
    props.timeOfDay === "day" ? colors.white : colors.black};
  color: ${(props) =>
    props.timeOfDay === "day" ? colors.black : colors.white};
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: ${(props) => props.cursorStyle ?? "default"};
  position: relative;
`;
