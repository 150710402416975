import styled from "styled-components";
import { colors } from "../styles/colors";
import { P } from "../styles/styled-components/Paragraph";
import { Show } from "./Show";

const SpeakerTextNight = styled.h2`
  color: ${colors.white};
`;
const SpeakerTextDay = styled.h2`
  color: ${colors.black};
`;

export function StoryText(props) {
  const { speaker, text, timeOfDay } = props;
  return (
    <Show when={speaker} whenFalse={<P>{text}</P>}>
      <Show
        when={timeOfDay === "night"}
        whenFalse={<SpeakerTextDay>{speaker}</SpeakerTextDay>}
      >
        <SpeakerTextNight>{speaker}</SpeakerTextNight>
      </Show>
      <P>
        <i>{text}</i>
      </P>
    </Show>
  );
}
