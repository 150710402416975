import styled from "styled-components";
import hasteLogo from "../assets/images/haste-logo.png";
import { colors } from "../styles/colors";

const Logo = styled.img`
  width: 125px;
  filter: ${colors.gray}; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
`;

export function HasteLogo() {
  return (
    <>
      <a href="https://app.hastearcade.com/" target="_blank" rel="noreferrer">
        <Logo src={hasteLogo} alt="haste logo" />
      </a>
    </>
  );
}
