import styled from "styled-components";

const Logo = styled.img`
  pointer-events: none;
  width: ${(props) => props.size ?? "150px"};
  z-index: 1;
  position: relative;
  margin: 1rem;
`;

export function StoryLogo(props) {
  const { storyBoard } = props;
  return <Logo src={storyBoard.logo} alt="logo" {...props} />;
}
