import { useState } from "react";
import styled from "styled-components";
import { Show } from "../../components/Show";
import { colors } from "../colors";

const ArrowButton = styled.button`
  padding: 1rem;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`;

export function SpeedArrow(props) {
  const { type, timeOfDay, slowDownClick, speedUpClick } = props;

  const [slowDownColor, setSlowDownColor] = useState(colors.gray);
  const [speedUpColor, setSpeedUpColor] = useState(colors.gray);

  return (
    <Show
      when={type === "slow-down"}
      whenFalse={
        <ArrowButton
          id="speed-up"
          onClick={speedUpClick}
          onMouseOver={() => {
            timeOfDay === "night"
              ? setSpeedUpColor(colors.white)
              : setSpeedUpColor(colors.black);
          }}
          onMouseLeave={() => setSpeedUpColor(colors.gray)}
        >
          <svg
            style={{pointerEvents: "none"}}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={speedUpColor}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              style={{pointerEvents: "none"}}
              id="speed-up"
              d="M2.46141 20.6531L10.5005 13.9547V10.0453L2.46141 3.34733C1.49437 2.54436 0 3.21842 0 4.50139V19.4967C0 20.7797 1.49437 21.4547 2.46141 20.6531ZM14.4609 20.6531L23.4609 13.1953C24.1758 12.592 24.1758 11.4905 23.4609 10.8872L14.4609 3.42936C13.4939 2.62686 11.9995 3.3005 11.9995 4.58342V19.5787C12 20.7797 13.4953 21.4547 14.4609 20.6531Z"
              fill={speedUpColor}
            />
          </svg>
        </ArrowButton>
      }
    >
      <ArrowButton
        onClick={slowDownClick}
        id="slow-down"
        onMouseOver={() => {
          timeOfDay === "night"
            ? setSlowDownColor(colors.white)
            : setSlowDownColor(colors.black);
        }}
        onMouseLeave={() => setSlowDownColor(colors.gray)}
      >
        <svg
          style={{pointerEvents: "none"}}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={slowDownColor}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_660_7853)">
            <path
              style={{pointerEvents: "none"}}
              d="M21.5391 3.34733L13.5 10.0458V13.9575L21.5391 20.6559C22.5047 21.4547 24 20.7797 24 19.4578V4.50139C24 3.21843 22.5047 2.54436 21.5391 3.34733ZM9.53907 3.34733L0.536255 10.8469C-0.178589 11.4501 -0.178589 12.5517 0.536255 13.155L9.53625 20.6128C10.5033 21.4153 11.9977 20.7417 11.9977 19.4587V4.46343C11.9578 3.21843 10.5047 2.54436 9.53907 3.34733Z"
              fill={slowDownColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_660_7853">
              <rect width="24" height="24" fill={slowDownColor} />
            </clipPath>
          </defs>
        </svg>
      </ArrowButton>
    </Show>
  );
}
