import React from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const storyIn = keyframes`
from {
  transform: translate3d(0,20px,0);
  opacity: 0;
}

to {
  transform: translate3d(0,0,0);
  opacity: 1;
}`;

const storyOut = keyframes`
from {
  transform: translate3d(0,0,0);
  opacity: 1;
}

to {
  transform: translate3d(0,-20px,0);
  opacity: 0;
}`;

const Wrapper = styled.div`
  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${(props) => {
      let type;
      switch (props.fadeStyle) {
        case "in":
          type = fadeIn;
          break;
        case "out":
          type = fadeOut;
          break;
        case "story-in":
          type = storyIn;
          break;
        case "story-out":
          type = storyOut;
          break;

        default:
          type = fadeIn;
          break;
      }
      return type;
    }};
    animation-fill-mode: both;
  }
`;

export function Fade({ duration = 1.5, delay = 0, children, ...delegated }) {
  duration *= 1000;
  delay *= 1000;
  return (
    <Wrapper
      {...delegated}
      style={{
        ...(delegated.style || {}),
        animationDuration: duration + "ms",
        animationDelay: delay + "ms",
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {children}
    </Wrapper>
  );
}

export default Fade;
